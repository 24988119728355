<template>
  <v-app>
    <v-navigation-drawer app color="grey lighten-3">
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="title">
            Charger Registry
          </v-list-item-title>
          <v-list-item-subtitle>
            Charging Stations from operators
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list dense nav>
        <template v-for="item in items">
          <v-list-item
              v-if="!item.sub"
              :key="item.title"
              link
              :to="item.path">
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-group
              v-else
              :key="item.title"
              :prepend-icon="item.icon"
              no-action>
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item
                v-for="(subEntry) in item.sub"
                :key="subEntry.title"
                :to="subEntry.path"
                link
            >
              <v-list-item-title>{{subEntry.title}}</v-list-item-title>

              <v-list-item-icon>
                <v-icon>{{subEntry.icon}}</v-icon>
              </v-list-item-icon>
            </v-list-item>

          </v-list-group>
        </template>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar app color="primary" dark>
      <v-app-bar-title>{{ $route.name }}</v-app-bar-title>
      <v-spacer></v-spacer>
      <v-btn icon @click="logout">
        <v-icon>mdi-logout-variant</v-icon>
      </v-btn>
    </v-app-bar>

    <!-- Sizes your content based upon application components -->
    <v-main>

      <!-- Provides the application the proper gutter -->
      <v-container fluid>

        <!-- If using vue-router -->
        <router-view></router-view>
      </v-container>
    </v-main>

    <v-footer app>
      <!-- -->
    </v-footer>
  </v-app>
</template>

<script>
export default {
  name: "Authed",
  data() {
    return {
      items: [
        {title: 'Dashboard', path: '/dashboard', icon: 'mdi-home'},
        {
          title: 'Operators',
          path: '/operators',
          icon: 'mdi-domain',
          sub: [
            {title: 'List', path: '/operators', icon: 'mdi-domain'},
            {title: 'Fetch locations', path: '/fetch', icon: 'mdi-table-sync'},
            {title: 'Fetch history', path: '/fetch-history', icon: 'mdi-history'},
            {title: 'Supercharger', path: '/supercharger', icon: 'mdi-table'},
          ]
        },
        {
          title: 'Locations',
          path: '/locations',
          icon: 'mdi-map-marker',
          sub: [
            {title: 'List', path: '/locations', icon: 'mdi-map-marker'},
            {title: 'Last changes', path: '/locations-changed', icon: 'mdi-update'},
            {title: 'Map', path: '/locations-map', icon: 'mdi-map'},
            {title: 'GE Overview', path: '/ge-overview', icon: 'mdi-map-plus'},
            {title: 'GE Compare', path: '/locations-goingelectric', icon: 'mdi-table'},
            {title: 'Compare CSV', path: '/locations-compare', icon: 'mdi-compare'},
            {title: 'Load Details', path: '/locations-details', icon: 'mdi-compare'},
          ]
        },
      ]
    }
  },
  methods: {
    logout() {
      this.$store.dispatch('logout')
          .then(() => {
            this.$router.push('/')
          })
          .catch(err => {
            console.log(err)
          });
    }
  }
}
</script>

<style scoped>

</style>
