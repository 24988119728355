import Vue from 'vue'
import VueRouter from 'vue-router'
import store from "@/plugins/store";

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Login',
        meta: {authenticated: false},
        component: () => import(/* webpackChunkName: "operators" */ '../views/Login.vue')
    },
    {
        path: '/dashboard',
        name: 'Dashboard',
        meta: {authenticated: true},
        component: () => import(/* webpackChunkName: "operators" */ '../views/Dashboard.vue')
    },
    {
        path: '/operators',
        name: 'Operators',
        meta: {authenticated: true},
        component: () => import(/* webpackChunkName: "operators" */ '../views/Operators.vue')
    },
    {
        path: '/locations',
        name: 'Locations',
        meta: {authenticated: true},
        component: () => import(/* webpackChunkName: "operators" */ '../views/Locations.vue')
    },
    {
        path: '/locations-changed',
        name: 'Last changed locations',
        meta: {authenticated: true},
        component: () => import(/* webpackChunkName: "operators" */ '../views/LocationsLastChanged.vue')
    },
    {
        path: '/locations-map',
        name: 'Locations Map',
        meta: {authenticated: true},
        component: () => import(/* webpackChunkName: "operators" */ '../views/LocationsMap.vue')
    },
    {
        path: '/supercharger',
        name: 'Supercharger',
        meta: {authenticated: true},
        component: () => import(/* webpackChunkName: "operators" */ '../views/Supercharger.vue')
    },
    {
        path: '/locations-goingelectric',
        name: 'Locations GoingElectric',
        meta: {authenticated: true},
        component: () => import(/* webpackChunkName: "operators" */ '../views/LocationsGoingelectric.vue')
    },
    {
        path: '/fetch',
        name: 'Fetch',
        meta: {authenticated: true},
        component: () => import(/* webpackChunkName: "operators" */ '../views/Fetch.vue')
    },
    {
        path: '/fetch-history',
        name: 'Fetch history',
        meta: {authenticated: true},
        component: () => import(/* webpackChunkName: "operators" */ '../views/OperatorsFetchHistory.vue')
    },
    {
        path: '/locations-compare',
        name: 'Compare CSV locations',
        meta: {authenticated: true},
        component: () => import(/* webpackChunkName: "operators" */ '../views/CsvCompare.vue')
    },
    {
        path: '/ge-overview',
        name: 'GoingElectric Overview',
        meta: {authenticated: true},
        component: () => import(/* webpackChunkName: "operators" */ '../views/GoingelectricOverview.vue')
    },
    {
        path: '/locations-details',
        name: 'Load location details',
        meta: {authenticated: true},
        component: () => import(/* webpackChunkName: "operators" */ '../views/LoadDetails.vue')
    },
]

const router = new VueRouter({
    mode: "history",
    routes
});

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.authenticated)) {
        if (store.getters.isLoggedIn) {
            next();
            return;
        }
        next('/');
    } else {
        next();
    }
})

export default router
