import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex);
const instance = axios.create({
    baseURL: 'https://data.lphp.at/creg',
    // timeout: 1000,
    // headers: {'Authorization': 'Bearer ' + localStorage.getItem('token') || ''}
});
if (localStorage.getItem('token') !== null) {
    instance.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('token');
}
Vue.prototype.$http = instance;

export default new Vuex.Store({
    state: {
        status: '',
        token: localStorage.getItem('token') || '',
        user: {}
    },
    mutations: {
        auth_request(state) {
            state.status = 'loading'
        },
        auth_success(state, token, user) {
            state.status = 'success'
            state.token = token
            state.user = user
        },
        auth_error(state) {
            state.status = 'error'
        },
        logout(state) {
            state.status = ''
            state.token = ''
        },
    },
    actions: {
        login({commit}, user) {
            return new Promise((resolve, reject) => {
                commit('auth_request');
                instance({url: '/auth/authenticate', data: user, method: 'POST'})
                    .then(resp => {
                        const data = resp.data.data;
                        const token = data.token;
                        const user = data.user;
                        localStorage.setItem('token', token);
                        instance.defaults.headers.common['Authorization'] = 'Bearer ' + token;
                        commit('auth_success', token, user);
                        resolve(resp);
                    })
                    .catch(err => {
                        commit('auth_error');
                        localStorage.removeItem('token');
                        reject(err);
                    })
            })
        },
        logout({commit}) {
            return new Promise((resolve) => {
                commit('logout');
                localStorage.removeItem('token');
                delete instance.defaults.headers.common['Authorization'];
                resolve()
            })
        }
    },
    getters: {
        isLoggedIn: state => !!state.token,
        authStatus: state => state.status,
    }
})
